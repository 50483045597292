.react-tel-input .form-control{
  width: 100%
}
.phoneForm{
  width: 94%;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.react-tel-input .country-list .country{
  text-align: left;
}
