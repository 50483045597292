.react-responsive-modal-overlay{
  padding: 0px
}


@media (min-width: 320px) and (max-width: 480px) {
  .react-responsive-modal-modal{
        width: 100%;
        position: relative;
  }
  .mapboxgl-ctrl-top-left .mapboxgl-ctrl{
        width: 100%;
  }
}
